<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.keyWord' placeholder="请输入关键字" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="数据来源:">
                        <el-select v-model="filters.dataSource" clearable>
                            <el-option
                                v-for="item in dataSourceArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="待清洗原因:">
                        <el-select v-model="filters.pendingClearcause" clearable>
                            <el-option
                                v-for="item in pendingClearcauseArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            @current-change="selectCurrentRow"
            style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="Name" align="center" label="姓名" min-width="100"></el-table-column>
            <el-table-column prop="PhoneNumber" align="center" label="手机号" min-width="130"></el-table-column>
            <el-table-column prop="UnitName" align="center" label="单位名称" min-width="150"></el-table-column>
            <el-table-column prop="ResourceType" align="center" label="数据来源" min-width="130">
                <template scope="scope">
                    <el-tag v-if="scope.row.ResourceType == 0" type="">数据中台</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 1" type="warning">H5自行填报</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 2" type="info">大数据甄选</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 3" type="success">自主注册</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CleanReason" align="center" label="待清洗原因" min-width="150">
                <template scope="scope">
                    <el-tag v-if="scope.row.CleanReason == 0" type="">相同单位手机号重复</el-tag>
                    <el-tag v-if="scope.row.CleanReason == 1" type="danger">号码不符合规则</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!--新增/编辑界面-->
        <el-dialog
            :title="addOrEdi?'新增':'编辑'"
            :visible.sync="addFormVisible"
            v-model="addFormVisible"
            :close-on-click-modal="false"
        >
            <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData">
                <el-form-item label="姓名:" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号:" prop="phone">
                    <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="单位名称:" prop="unitName">
                    <el-input v-model="formData.unitName" placeholder="请输入单位名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getDataOfClean,addNoticeTemplate,updateDataOfClean,deleteNoticeTemplate } from '../../api/api'
import util from "../../../util/date";
import {validPhone} from '../../../util/validate';
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return{
            filters: {
                keyWord: "",
                dataSource: "",
                pendingClearcause: "",
            },
            buttonList: [],
            listLoading: false,
            listData: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            addOrEdi: true,
            addFormVisible: false,
            addLoading:false,
            formData: {
                name: '',
                phone: '',
                unitName: '',
            },
            formDataRules: {
                name: [{required: true, message: "请输入姓名", trigger: "blur" }],
                phone: [{required: true, message: "请输入手机号", trigger: "blur" }],
                unitName: [{required: true, message: "请输入单位名称", trigger: "blur" }],
            },
            editData: '',
            dataSourceArr: [
                {value: 0, name: "数据中台"},
                {value: 1, name: "H5自行填报"},
                {value: 2, name: "大数据甄选"},
                {value: 3, name: "自主注册"},
            ],
            pendingClearcauseArr: [
                {value: 0, name: "相同单位手机号重复"},
                {value: 1, name: "号码不符合规则"},
            ],
            isEnableArr: [
                {value: true, name: "是"},
                {value: false, name: "否"},
            ],
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            var _this = this
            var params = {
                keyWord: this.filters.keyWord,
                resourceType: this.filters.dataSource,
                cleanReason: this.filters.pendingClearcause,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true;
            getDataOfClean(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.pages.dataCount = result.Response.DataCount
                    this.listData = result.Response.Data
                }
                _this.listLoading = false;
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val           
            this.getListData();
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.editData = val
        },
        // 查询
        getTableDataFun() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.formData = {
                name: '',
                phone: '',
                unitName: '',
            },
            this.addOrEdi = true;
            this.addFormVisible = true;
        },
        // 编辑
        handleEdit() {
            if(this.editData) {
                this.addOrEdi = false;
                this.addFormVisible = true;
                this.addLoading = false;
                this.formData = {
                    name: this.editData.Name,
                    phone: this.editData.PhoneNumber,
                    unitName: this.editData.UnitName,
                }
            }else{
                this.$message({
                    message: '请选择要编辑数据！',
                    type: 'warning'
                });
                return;
            }
        },
        // 删除
        handleDel() {
            if(this.editData) {
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.editData.ID
                    }
                    deleteNoticeTemplate(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除数据！',
                    type: 'warning'
                });
                return;
            }
        },
        // 新增编辑提交
        addSubmit() {
            var _this = this
            this.$refs.formData.validate(valid => {
                if (valid) {
                    var params = {
                        Name: this.formData.name,
                        PhoneNumber: this.formData.phone,
                        UnitName: this.formData.unitName,
                    }
                    if(!validPhone(this.formData.phone)) {
                        this.$message.warning("请输入正确的手机号")
                        return
                    }
                    if(this.addOrEdi){
                        this.addLoading = true;
                        // 遗弃功能
                        // this.secAddFun(params);
                    }else{
                        params.Id = this.editData.ID
                        params.UnitID = this.editData.UnitID
                        this.addLoading = true;
                        this.secEditFun(params);
                    }
                }
            })
        },
        // 新增二次封装
        secAddFun(params) {
            var _this = this
            addNoticeTemplate(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    _this.addFormVisible = false;
                    _this.addLoading = false;
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    _this.addLoading = false;
                }
            })
        },
        // 编辑二次封装
        secEditFun(params) {
            var _this = this
            updateDataOfClean(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    _this.addFormVisible = false;
                    _this.addLoading = false;
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    _this.addLoading = false;
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>